<template>
    <div class="field grid align-items-start">
        <label class="col-fixed text-700 mt-1 w-4">
            {{ label }}
        </label>
        <div class="col">
            <div class="field grid" style="row-gap: 1rem;">
                <div class="col-6" v-for="key in allPossiblePermissions" :key="key">
                    <label class="block mb-2">{{ key|dataTrans('permissions') }}</label>
                    <Checkbox v-model="internValue[key]" :binary="true"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Checkbox from "primevue/checkbox/Checkbox";

    export default {
        components: {
            Checkbox
        },
        props: {
            value: {
                type: [Array, Object],
                required: true,
                default: () => {
                    return {};
                }
            },
            label: {
                type: String,
                default: () => 'Berechtigungen'
            }
        },
        data() {
            return {
                internValue: {},
            }
        },
        mounted() {
            this.internValue = this.allPossiblePermissions.reduce((a, v) => ({...a, [v]: null}), {});
            this.allPossiblePermissions.forEach((item) => {
                if (this.value.hasOwnProperty(item)) {
                    this.internValue[item] = this.value[item];
                }
            });
        },
        watch: {
            internValue: {
                handler: function (newVal) {
                    this.$emit('input', newVal);
                },
                deep: true,
            },
            value: {
                handler: function (newVal) {
                    Object.keys(newVal).forEach((item) => {
                        this.internValue[item] = newVal[item];
                    });
                },
                deep: true
            }
        },
        computed: {
            allPossiblePermissions() {
                return Object.keys(this.$store.getters.settings.permissions);
            }
        }
    }
</script>
